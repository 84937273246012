import Alpine from 'alpinejs'
import * as Sentry from "@sentry/browser";

import accordion from './data/accordion';
import archive from './data/archive';
import buttons from './data/buttons';
import form from './data/form';
import features from './data/features';
import awards from './data/awards';
import modals from './data/modals';
import menu from './data/menu';
import vimeo from './data/vimeo';

if (import.meta.env.VITE_SENTRY_DSN_PUBLIC) {
  console.log('Initialising Sentry');

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
  });
}

window.Alpine = Alpine;
Alpine.data('accordion', accordion);
Alpine.data('archive', archive);
Alpine.data('buttons', buttons);
Alpine.data('ajaxForm', form);
Alpine.data('features', features);
Alpine.data('awards', awards);
Alpine.data('menu', menu);
Alpine.data('modals', modals);
Alpine.data('vimeo', vimeo);

Alpine.start();
